































































































import { Component, Vue } from 'vue-property-decorator'
//@ts-ignore
import { QrcodeStream } from 'vue-qrcode-reader'
import CheckDevice from '@/helpers/CheckDevice'
import i18n from '@/lang'

import SettingService from '@/services/SettingService'
import UserService from '@/services/UserService'
import AuthService from '@/services/AuthService'

import store from '@/store'
import { getModule } from 'vuex-module-decorators'
import Setting from '@/store/modules/Setting'
import Auth from '@/store/modules/Auth'
import UserInfo from '@/store/modules/UserInfo'

const SettingModule = getModule(Setting, store)
const AuthModule = getModule(Auth, store)
const UserModule = getModule(UserInfo, store)

@Component({ components: { QrcodeStream } })
export default class EmployeeWithoutGroup extends Vue {
  private camera: string = 'auto'
  private result: string = ''
  private lastResult: string = ''
  private infoMess: string = ''
  private isMobile: boolean = false

  created() {
    this.isMobile = CheckDevice.isMobile()
    this.checkUserBelongToGroup()
  }

  checkUserBelongToGroup() {
    AuthService.checkUserBelongToGroup().then(response => {
      if (response.status === 200) {
        if (response.data.is_user_belong_to_group === true) {
          this.$router.push({
            name: 'home'
          })
          this.getUserInfo()
          this.resetStoreToDefault()
          this.getUserSetting()
          this.getUserProfile()
        }
      }
    })
  }

  resetStoreToDefault() {
    UserModule.RESET_DEFAULT()
    SettingModule.RESET_DEFAULT()
  }
  getUserInfo() {
    UserService.getUserInfo()
      .then(response => {
        if (response.status === 200) {
          UserModule.HANDLE_USER_INFO(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  getUserSetting() {
    SettingService.getUserSetting()
      .then(async response => {
        if (response.status === 200) {
          await SettingModule.HANDLE_USER_SETTING(response.data)
          i18n.locale = store.state.setting.setting.setting_language.code
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  getUserProfile() {
    UserService.getUserProfile()
      .then(response => {
        if (response.status === 200) {
          UserModule.HANDLE_USER_PROFILE(response.data)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  async backToLogin() {
    var params = {
      token: sessionStorage.getItem('device_token')
    }
    await AuthService.logout(params)
      .then(response => {
        if (response.status === 200) {
          AuthModule.SET_TOKEN_NULL()
          i18n.locale = 'ja'
          this.$router.push({
            name:
              this.$store.state.auth.loginFromLoginByEmail === true
                ? 'email-login'
                : 'login'
          })
        }
      })
      .catch(error => {
        if (error) {
          AuthModule.SET_TOKEN_NULL()
          i18n.locale = 'ja'
          this.$router.push({
            name:
              this.$store.state.auth.loginFromLoginByEmail === true
                ? 'email-login'
                : 'login'
          })
        }
      })
    sessionStorage.clear()
    localStorage.clear()
  }

  /**
   * Init QR scan
   */
  async onInit(promise: any) {
    try {
      await promise
    } catch (err) {
      const error = err as any
      if (error.name === 'NotAllowedError') {
        this.openInfoModal(this.$t('common.message.not_allowed_error'))
      } else if (error.name === 'NotFoundError') {
        this.openInfoModal(this.$t('common.message.not_found_error'))
      } else if (error.name === 'NotSupportedError') {
        this.openInfoModal(this.$t('common.message.not_supported_error'))
      } else if (error.name === 'NotReadableError') {
        this.openInfoModal(this.$t('common.message.not_readable_error'))
      } else if (error.name === 'OverconstrainedError') {
        this.openInfoModal(this.$t('common.message.overconstrained_error'))
      } else if (error.name === 'StreamApiNotSupportedError') {
        this.openInfoModal(
          this.$t('common.message.stream_api_not_supported_error')
        )
      }
    }
  }

  /**
   * Open info modal
   */
  openInfoModal(mess: any) {
    this.infoMess = mess as string
    this.$bvModal.show('camera-info-mess')
  }

  goToResult() {
    this.$bvModal.hide('qr-scan-modal')
    window.location.replace(this.lastResult)
  }

  /**
   * Hanlde decode QR
   */
  async onDecode(content: string) {
    this.result = content
    this.lastResult = content
  }

  /**
   * Paint outline for QR
   */
  paintOutline(detectedCodes: any, ctx: CanvasRenderingContext2D) {
    for (const detectedCode of detectedCodes) {
      const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

      ctx.lineWidth = 3
      ctx.strokeStyle = '#ffda54'

      ctx.beginPath()
      ctx.moveTo(firstPoint.x, firstPoint.y)
      for (const { x, y } of otherPoints) {
        ctx.lineTo(x, y)
      }
      ctx.lineTo(firstPoint.x, firstPoint.y)
      ctx.closePath()
      ctx.stroke()
    }
  }

  hideModalQr() {
    this.result = ''
  }
}
